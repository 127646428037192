<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showSnackBar" color="#000" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog
      v-model="addDataDialog"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs' ||
        $vuetify.breakpoint.name == 'sm' ||
        $vuetify.breakpoint.name == 'md'
          ? '100vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '40vw'
          : '50vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center py-4>
          <v-flex xs12 lg10>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-layout wrap justify-start>
                <v-flex xs12 sm12 text-left px-2>
                  <!-- <span class="itemValue">{{ dataType }} </span> -->
                  <v-text-field
                    ref="name"
                    required
                    class="formClass"
                    label="Name"
                    :rules="[rules.required]"
                    dense
                    v-model="name"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-flex xs12 sm12 text-left px-2>
                <v-combobox
                  required
                  clearable
                  v-model="pincodes"
                  :items="pinList"
                  multiple
                  label="Please type and press enter"
                  persistent-hint
                  small-chips
                ></v-combobox>
              </v-flex>

              <v-layout wrap justify-center>
                <v-flex xs6 sm4 xl3 px-2>
                  <v-btn
                    block
                    tile
                    outlined
                    color="#FF1313"
                    light
                    :ripple="false"
                    depressed
                    @click="addDataDialog = false"
                    class="itemValue"
                  >
                    Cancel
                  </v-btn>
                </v-flex>
                <v-flex xs6 sm4 xl3 px-2 v-if="edit == false">
                  <v-btn
                    block
                    tile
                    color="#000"
                    light
                    :ripple="false"
                    depressed
                    :disabled="!valid"
                    @click="validate"
                    class="itemValue"
                  >
                    <!-- <span style="color: #fff" v-if="storage">Edit {{ dataType }}</span> -->
                    <span style="color: #fff">SAVE</span>
                  </v-btn>
                </v-flex>
                <v-flex xs6 sm4 xl3 px-2 v-if="edit == true">
                  <v-btn
                    block
                    tile
                    color="#000"
                    light
                    :ripple="false"
                    depressed
                    :disabled="!valid"
                    @click="validateEdit"
                    class="itemValue"
                  >
                    <!-- <span style="color: #fff" v-if="storage">Edit {{ dataType }}</span> -->
                    <span style="color: #fff">EDIT</span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-form>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-start pt-3 pl-2 pr-5 px-6>
      <v-flex align-self-center xs12 sm10 md10 lg10 xl10 px-5 text-left>
        <span style="color:black; font-weight:bold; font-size:18px" class="itemHeading1"> Zones {{ pinName }}</span>
      </v-flex>

      <v-flex align-self-end xs6 sm2 md2 lg2 xl2 px-3>
        <v-btn
          block
          tile
          depressed
          outlined
          color="#000000"
          :ripple="false"
          @click="addDataDialog = true"
          class="itemValue"
        >
          Add Zone
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pt-5 pa-5>
      <v-flex xs12 sm6 md6 v-for="(item, i) in zone" :key="i" pa-2>
        <v-card outlined elevation="0">
          <v-layout wrap justify-center pa-3>
            <v-flex xs12 sm10>
              <v-layout wrap justify-start>
                <v-flex xs12 sm8 lg6>
                  <v-flex xs12 text-left>
                    <span style="font-size: 13px; color: #8e8e8e">Name </span>
                  </v-flex>
                  <v-flex xs12 sm12 text-left>
                    <span
                      style="
                        font-size: 14px;
                        color: #000000;
                        text-transform: none;
                      "
                    >
                      {{ item.name }}
                      <span v-if="!item.name">-</span>
                    </span>
                  </v-flex>
                </v-flex>

                <v-flex align-self-center xs6 sm3 md3 xl3 px-2>
                  <v-btn
                    block
                    tile
                    small
                    depressed
                    outlined
                    color="green"
                    :ripple="false"
                    class="itemValue"
                    @click="editDialog(item)"
                  >
                    <span style="color: green"> Edit </span>
                  </v-btn>
                </v-flex>
                <v-flex align-self-center xs6 sm3 md3 xl3 px-2>
                  <v-dialog v-model="item.deleteDialogue" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        block
                        tile
                        small
                        depressed
                        outlined
                        color="#FF1313"
                        :ripple="false"
                        v-on="on"
                        v-bind="attrs"
                        class="itemValue"
                      >
                        <span style="color: #ff1313"> Delete </span>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-layout pa-4 wrap justify-center>
                        <v-flex xs12>
                          <span
                            style="
                              color: #000000;
                              font-family: sofiaProSemibold;
                              font-size: 18px;
                            "
                            >Do you want to delete this Zone ?</span
                          >
                        </v-flex>
                        <v-flex xs6 px-2 py-4>
                          <v-btn
                            outlined
                            tile
                            block
                            depressed
                            dark
                            color="#C62525"
                            class="itemValue"
                            @click="deleteData(item)"
                          >
                            Yes
                          </v-btn>
                        </v-flex>
                        <v-flex xs6 px-2 py-4>
                          <v-btn
                            tile
                            block
                            depressed
                            dark
                            color="#1FB436"
                            @click="item.deleteDialogue = false"
                            class="itemValue"
                          >
                            No
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-dialog>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      deleteDialogue: false,
      banDialogue: false,
      activateDialogue: false,
      zone: [],
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      name: null,
      pincodes: [],
      pinName: "",
      pinList: [],
      addDataDialog: false,
      valid: true,
      edit: false,
      editId: "",
      rules: {
        required: (value) => !!value || " Required Field",
      },
    };
  },
  beforeMount() {
    this.getData();
    this.pincodeList();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/zone/List",
        params: {
          id: this.$route.query.id,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.zone = response.data.data;
              this.pinName = response.data.name;
              this.appLoading = false;
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    validate() {
      if (this.$refs.form.validate()) this.addData();
    },
    validateEdit() {
      if (this.$refs.form.validate()) this.updateData();
    },
    addData() {
      axios({
        method: "post",
        url: "/add/Zone",
        data: {
          id: this.$route.query.id,
          name: this.name,
          pincodes: this.pincodes,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.addDataDialog = false;
            this.showSnackBar = true;
            this.msg = "Added Successfully";
            this.getData();
            (this.name = ""), (this.pincodes = "");
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    updateData() {
      axios({
        method: "post",
        url: "/edit/Zone",
        data: {
          id: this.editId,
          name: this.name,
          pincodes: this.pincodes,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.addDataDialog = false;
            this.showSnackBar = true;
            this.msg = "Updated Successfully";
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    pincodeList() {
      axios({
        method: "POST",
        url: "/zone/Pincodes/list",
        data: {
          id: this.$route.query.id,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.pinList = response.data.data;
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editDialog(item) {
      this.edit = true;
      this.editing = item;
      this.addDataDialog = true;
      this.name = item.name;
      this.pincodes = item.pincodes;
      this.editId = item._id;
    },
    deleteData(id) {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/delete/Zone",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: id._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.deleteDialogue = false;
            this.showSnackBar = true;
            this.msg = "Deleted Successfully";
            this.getData();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
